<template>
  <div>
    <v-page id="kamers" background-color="#F1EDED" justify="center" align="center" class="d-flex text-center mt-16">
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("kamers.header") }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("kamers.kamers") }}</h1></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1" v-for="(kamer, kamerIdx) in kamers" :key="kamerIdx">
            <room-card :src="kamer.fotos[0]" :header-text="kamer.titel" :to="{name: 'KamerSingular', params: {id: kamer.id, locale: $i18n.locale}}">
              {{ kamer.text[language].substring(0, 250) }} <span v-if="kamer.text[language].length > 250">...</span>
            </room-card>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import RoomCard from "@/components/RoomCard";
import Footer from "@/views/Footer";
import Rooms from "@/data/rooms";

export default {
  name: "KamerCategory",
  components: {RoomCard, Footer},
  data() {
    return {
      kamers: Rooms.filter(x => x.category.toLowerCase() === this.$route.params.category)
    }
  },
  computed: {
    language() {
      return this.$i18n.locale;
    }
  },
}
</script>

<style scoped>

</style>