<template>
  <div>
    <v-page id="kamers" justify="center" align="center" class="d-flex text-center mt-16">
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("kamers.header") }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("kamers.kamers") }}</h1></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" lg="8" xl="6">
            <i18n path="kamers.intro" tag="label" for="kamers.intro">
              <a href="https://nilsonbeds.nl/" target="_blank">Nilson</a>
            </i18n>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="rooms.find(x => x.category == 'Cozy').fotos[0]" :header-text="$t('kamers.cozy-header')" :to="{name: 'KamerCategory', params: { category: 'cozy', locale: $i18n.locale}}">
              {{ $t("kamers.cozyroom") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="rooms.find(x => x.category == 'Comfort').fotos[0]" :header-text="$t('kamers.comfy-header')" :to="{name: 'KamerCategory', params: { category: 'comfort', locale: $i18n.locale}}">
              {{ $t("kamers.comfyroom") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="rooms.find(x => x.category == 'Suites').fotos[0]" :header-text="$t('kamers.suites-header')" :to="{name: 'KamerCategory', params: { category: 'suites', locale: $i18n.locale}}">
              {{ $t("kamers.suites") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="rooms.find(x => x.category == 'Garden').fotos[0]" :header-text="$t('kamers.garden-header')" :to="{name: 'KamerCategory', params: { category: 'garden', locale: $i18n.locale}}">
              {{ $t("kamers.garden") }}
            </room-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="rooms.find(x => x.category == 'Family').fotos[0]" :header-text="$t('kamers.family-header')" :to="{name: 'KamerCategory', params: { category: 'family', locale: $i18n.locale}}">
              {{ $t("kamers.family") }}
            </room-card>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col>
            <v-carousel height="150" hide-delimiter-background hide-delimiters>
              <v-carousel-item>
                <v-row>
                  <v-col v-for="n in 6" :key="n" cols="2">
                    <v-card>
                      <v-img
                          height="125"
                          :src="fotos[n % fotos.length]"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
              <v-carousel-item>
                <v-row>
                  <v-col v-for="n in 6" :key="n" cols="2">
                    <v-card>
                      <v-img
                          height="125"
                          :src="fotos[n + 5 % fotos.length]"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-carousel height="200" hide-delimiter-background hide-delimiters>
              <v-carousel-item v-for="n in 12" :key="n">
                <v-row>
                  <v-col>
                    <v-card>
                      <v-img
                          height="200"
                          :src="fotos[n % fotos.length]"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import RoomCard from "@/components/RoomCard";
import Footer from "@/views/Footer";
import VPage from "@/components/VPage";
import Rooms from  "@/data/rooms";
export default {
name: "Kamers",
  components: {VPage, RoomCard, Footer},
  data() {
  return {
    fotos: Rooms.map(x => x.fotos[0]),
    rooms: Rooms
  }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
