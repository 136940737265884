<template>
  <v-page background-color="#91B6BA" :background="require('@/assets/watermerk.svg?fill=#FFFFFF')" style="background-size: 100%;background-position:center;" class="text-center pt-16" no-auto-height>
    <v-container>
      <v-row>
        <v-col>
          <v-carousel height="750" light hide-delimiter-background v-if="$vuetify.breakpoint.smAndUp">
            <v-carousel-item>
              <v-row>
                <v-col cols="4" class="d-flex flex-grow-1">
                  <room-card :src="rooms.find(x => x.category == 'Cozy').fotos[0]" :header-text="$t('kamers.cozy-header')" :to="{name: 'KamerCategory', params: { category: 'cozy', locale: $i18n.locale}}">
                    {{ $t("kamers.cozyroom") }}
                  </room-card>
                </v-col>
                <v-col cols="4" class="d-flex flex-grow-1">
                  <room-card :src="rooms.find(x => x.category == 'Comfort').fotos[0]" :header-text="$t('kamers.comfy-header')" :to="{name: 'KamerCategory', params: { category: 'comfort', locale: $i18n.locale}}">
                    {{ $t("kamers.comfyroom") }}
                  </room-card>
                </v-col>
                <v-col cols="4" class="d-flex flex-grow-1">
                  <room-card :src="rooms.find(x => x.category == 'Suites').fotos[0]" :header-text="$t('kamers.suites-header')" :to="{name: 'KamerCategory', params: { category: 'suites', locale: $i18n.locale}}">
                    {{ $t("kamers.suites") }}
                  </room-card>
                </v-col>
              </v-row>
            </v-carousel-item>
            <v-carousel-item>
              <v-row justify="center">
                <v-col cols="4" class="d-flex flex-grow-1">
                  <room-card :src="rooms.find(x => x.category == 'Garden').fotos[0]" :header-text="$t('kamers.garden-header')" :to="{name: 'KamerCategory', params: { category: 'garden', locale: $i18n.locale}}">
                    {{ $t("kamers.garden") }}
                  </room-card>
                </v-col>
                <v-col cols="4" class="d-flex flex-grow-1">
                  <room-card :src="rooms.find(x => x.category == 'Family').fotos[0]" :header-text="$t('kamers.family-header')" :to="{name: 'KamerCategory', params: { category: 'family', locale: $i18n.locale}}">
                    {{ $t("kamers.family") }}
                  </room-card>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
          <v-carousel v-else light height="625" hide-delimiter-background>
            <v-carousel-item>
              <room-card :src="rooms.find(x => x.category == 'Cozy').fotos[0]" :header-text="$t('kamers.cozy-header')" :to="{name: 'KamerCategory', params: { category: 'cozy', locale: $i18n.locale}}">
                {{ $t("kamers.cozy") }}
              </room-card>
            </v-carousel-item>
            <v-carousel-item>
              <room-card :src="rooms.find(x => x.category == 'Comfort').fotos[0]" :header-text="$t('kamers.comfy-header')" :to="{name: 'KamerCategory', params: { category: 'comfort', locale: $i18n.locale}}">
                {{ $t("kamers.comfy") }}
              </room-card>
            </v-carousel-item>
            <v-carousel-item>
              <room-card :src="rooms.find(x => x.category == 'Suites').fotos[0]" :header-text="$t('kamers.suites-header')" :to="{name: 'KamerCategory', params: { category: 'suites', locale: $i18n.locale}}">
                {{ $t("kamers.suites") }}
              </room-card>
            </v-carousel-item>
            <v-carousel-item>
              <room-card :src="rooms.find(x => x.category == 'Garden').fotos[0]" :header-text="$t('kamers.garden-header')" :to="{name: 'KamerCategory', params: { category: 'garden', locale: $i18n.locale}}">
                {{ $t("kamers.garden") }}
              </room-card>
            </v-carousel-item>
            <v-carousel-item>
              <room-card :src="rooms.find(x => x.category == 'Family').fotos[0]" :header-text="$t('kamers.family-header')" :to="{name: 'KamerCategory', params: { category: 'family', locale: $i18n.locale}}">
                {{ $t("kamers.family") }}
              </room-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </v-page>
</template>

<script>
import VPage from "@/components/VPage";
import RoomCard from "@/components/RoomCard";
import Rooms from "@/data/rooms";
export default {
name: "MoreRooms",
  components: {VPage, RoomCard},
  data: () => ({
    rooms: Rooms
  })
}
</script>

<style scoped>

</style>