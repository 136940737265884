<template>
  <kamer-template :fotos="room.fotos" :titel="room.titel" :faciliteiten="room.faciliteiten">
    {{ room.text[language] }}
  </kamer-template>
</template>

<script>
import KamerTemplate from "@/views/Kamers/KamerTemplate";
import Rooms from "@/data/rooms";

export default {
  name: "KamerSingle",
  components: {KamerTemplate},
  data: () => ({
    room: null
  }),
  computed: {
    language() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    this.room = Rooms.find(x => x.id == this.$route.params.id);
  }

}
</script>

<style scoped>

</style>