<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <v-col :cols="fotos.length === 1 ? 12 : 8" v-if="fotos.length > 0" class="pa-0">
        <v-img :src="fotos[0]" height="780"></v-img>
      </v-col>
      <v-col cols="4" v-if="fotos.length > 1">
        <v-row v-if="fotos.length >= 2">
          <v-col><v-img :src="fotos[1]" :height="fotos.length > 2 ? 375 : 750"></v-img></v-col>
        </v-row>
        <v-row class="mt-1" v-if="fotos.length >= 3">
          <v-col><v-img :src="fotos[2]" height="375"></v-img></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-carousel height="300" hide-delimiter-background>
        <v-carousel-item v-for="(foto, fotoidx) in fotos" :key="fotoidx">
          <v-img :src="foto" :aspect-ratio="16/9"></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-page background-color="#F1EDED" class="py-16" no-auto-height>
      <v-container>
        <v-row align="center" justify="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ titel }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left" cols="12" lg="9">
                <p><slot></slot></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <span style="text-transform:uppercase;font-weight:bold;" class="c-primary">{{ $t('kamers.allefaciliteiten') }}</span>
              </v-col>
            </v-row>
            <v-row justify="center" class="pb-6">
              <v-col cols="10" md="12">
                <carousel perPage="4" :perPageCustom="[[768, 5], [1024, 8]]" :scrollPerPage="$vuetify.breakpoint.smAndDown" :paginationEnabled="false" :navigationEnabled="true" navigationPrevLabel="<" navigationNextLabel=">">
                  <slide v-for="(faciliteit, faciliteitIdx) in faciliteiten" :key="faciliteitIdx">
                    <div class="d-flex align-center flex-column" style="text-align:center;">
                      <div style="height: 75px" class="d-flex align-center justify-center"><v-img :src="faciliteit.icon" max-width="48" width="48"></v-img></div>
                      {{ $t(faciliteit.text) }}
                    </div>
                  </slide>
                </carousel>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn style="text-transform:uppercase;" class="font-weight-bold rounded-0 px-10 py-5" color="primary" href="https://app.mews.com/distributor/b432a174-6e19-4e76-b748-ace10112d0fc" target="_blank">{{ $t('kamers.boeknu')}}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <more-rooms></more-rooms>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/Footer";
import VPage from "@/components/VPage";
import MoreRooms from "@/components/rooms/MoreRooms";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "KamerTemplate",
  components: {MoreRooms, VPage, Footer, Carousel, Slide},
  props: {
    fotos: {
      type: Array,
      default: () => []
    },
    faciliteiten: {
      type: Array,
      default: () => []
    },
    titel: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
  .VueCarousel-navigation-button {
    color: var(--v-primary-base) !important;
  }
</style>